import { render, staticRenderFns } from "./TreeviewItem.vue?vue&type=template&id=d527f49e&scoped=true&"
import script from "./TreeviewItem.vue?vue&type=script&lang=js&"
export * from "./TreeviewItem.vue?vue&type=script&lang=js&"
import style0 from "./TreeviewItem.vue?vue&type=style&index=0&id=d527f49e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d527f49e",
  null
  
)

export default component.exports