<!--
  Form used to save, add and edit searches
-->

<template>
  <div>
    <v-card
      v-if="isRecent"
      color="#37474F"
      :height="$vuetify.breakpoint.lgAndDown ? 172 : 180"
      @click.self.stop="updateSearchParameters()"
    >
      <v-tooltip
        open-delay="500"
        left
        :disabled="$vuetify.breakpoint.mdAndDown"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="isRecent"
            absolute
            class="mr-n2 mt-2 mb-10 fab-button"
            dark
            small
            fab
            bottom
            right
            color="teal lighten-1"
            @click.stop="save()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Zoekopdracht opslaan</span>
      </v-tooltip>
      <v-card-text
        class="white--text"
        :class="$vuetify.breakpoint.lgAndDown ? 'pr-8' : 'pr-14'"
        @click.stop="updateSearchParameters()"
      >
        <component
          :is="savedSearchesItemDetailRecentString"
          :search="search"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="isSaved"
      :class="{'query--active': isActive }"
      class="query white--text mb-2"
      :height="$vuetify.breakpoint.lgAndDown ? 172 : 180"
      color="#37474F"
      @click.self.stop="updateSearchParameters()"
    >
      <v-btn
        class="mr-n2 mb-7"
        absolute
        bottom
        right
        fab
        dark
        x-small
        depressed
        color="transparent"
        @click.stop="showShareSavedSearchDialog"
      >
        <v-icon
          style="opacity: 0.7; z-index: -1;"
        >
          mdi-share-variant
        </v-icon>
      </v-btn>
      <v-speed-dial
        v-model="fab"
        top
        right
        absolute
        direction="bottom"
        transition="slide-y-transition"
        @click.stop.prevent="nothing"
      >
        <template v-slot:activator>
          <v-btn
            class="mt-n3 mr-n2"
            v-model="fab"
            icon
            dark
            small
            depressed
            :ripple="false"
            @click="triggerEditMode"
          >
            <v-icon
              style="opacity: 0.7;"
            >
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>

        <v-tooltip
          open-delay="1000"
          left
          :disabled="$vuetify.breakpoint.mdAndDown"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-n4"
              fab
              dark
              small
              color="red"
              @click.stop.prevent="removeInit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Verwijderen</span>
        </v-tooltip>
        <v-tooltip
          open-delay="1000"
          left
          :disabled="$vuetify.breakpoint.mdAndDown"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="orange"
              style="margin-top: -2px"
              @click.stop.prevent="edit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Bewerken</span>
        </v-tooltip>
        <v-tooltip
          open-delay="1000"
          left
          :disabled="$vuetify.breakpoint.mdAndDown"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="blue"
              style="margin-top: -2px"
              @click.stop.prevent="updateInit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Overschrijven</span>
        </v-tooltip>
      </v-speed-dial>
      <v-card-text
        class="white--text ml-n1 fill-height"
        :class="$vuetify.breakpoint.lgAndDown ? 'pr-8' : 'pr-14'"
        @click.stop="updateSearchParameters()"
      >
        <component
          :is="savedSearchesItemDetailSavedString"
          :search="search"
        />
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import { showDialog } from '@/helpers/dialogHelper';
import EventBus from '../event-bus';
import SavedSearchShareDialog from './SavedSearchShareDialog.vue';

export default {
  name: 'SavedSearchesItem',

  components: {
    DocumentSavedSearchesItemDetailRecent:
      () => import(/* webpackChunkName: "DocumentSavedSearchesItemDetailRecent" */
        '@/modules/document/components/DocumentSavedSearchesItemDetailRecent.vue'
      ),
    DocumentSavedSearchesItemDetailSaved:
      () => import(/* webpackChunkName: "DocumentSavedSearchesItemDetailSaved" */
        '@/modules/document/components/DocumentSavedSearchesItemDetailSaved.vue'
      ),
    EuroparlSavedSearchesItemDetailRecent:
      () => import(/* webpackChunkName: "EuroparlSavedSearchesItemDetailRecent" */
        '@/modules/europarl/components/EuroparlSavedSearchesItemDetailRecent.vue'
      ),
    EuroparlSavedSearchesItemDetailSaved:
      () => import(/* webpackChunkName: "EuroparlSavedSearchesItemDetailSaved" */
        '@/modules/europarl/components/EuroparlSavedSearchesItemDetailSaved.vue'
      ),
    AgendaSavedSearchesItemDetailRecent:
      () => import(/* webpackChunkName: "AgendaSavedSearchesItemDetailRecent" */
        '@/modules/agenda/components/AgendaSavedSearchesItemDetailRecent.vue'
      ),
    AgendaSavedSearchesItemDetailSaved:
      () => import(/* webpackChunkName: "AgendaSavedSearchesItemDetailSaved" */
        '@/modules/agenda/components/AgendaSavedSearchesItemDetailSaved.vue'
      ),
    LocalRegionalSavedSearchesItemDetailRecent:
      () => import(/* webpackChunkName: "LocalRegionalSavedSearchesItemDetailRecent" */
        '@/modules/local-regional/components/LocalRegionalSavedSearchesItemDetailRecent.vue'
      ),
    LocalRegionalSavedSearchesItemDetailSaved:
      () => import(/* webpackChunkName: "LocalRegionalSavedSearchesItemDetailSaved" */
        '@/modules/local-regional/components/LocalRegionalSavedSearchesItemDetailSaved.vue'
      ),
  },

  props: {
    savedSearchesCategoryString: {
      type: String,
      required: true,
    },
    currentSearchString: {
      type: String,
      required: true,
    },
    savedSearchesString: {
      type: String,
      required: true,
    },
    recentSearchesString: {
      type: String,
      required: true,
    },
    maxNumberOfSearchesString: {
      type: String,
      required: true,
    },
    allSearchParametersString: {
      type: String,
      required: true,
    },
    setCurrentSearchString: {
      type: String,
      required: true,
    },
    removeSearchString: {
      type: String,
      required: true,
    },
    updateSearchString: {
      type: String,
      required: true,
    },
    setAllSearchParametersString: {
      type: String,
      required: false,
    },
    setDialogFormSavedSearchesVisibleString: {
      type: String,
      required: false,
    },
    savedSearchesItemDetailRecentString: {
      type: String,
      required: false,
    },
    savedSearchesItemDetailSavedString: {
      type: String,
      required: false,
    },
    search: {
      type: Object,
      required: true,
    },
    current: {
      type: Object,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return value === 'recent' || value === 'saved';
      },
    },
  },

  data() {
    return {
      fab: null,
      busyRemove: false,
      busyUpdate: false,
      disabled: false,
    };
  },

  computed: {
    currentSearch() { return this.$store.getters[this.currentSearchString]; },

    savedSearches() { return this.$store.getters[this.savedSearchesString]; },

    maxNumberOfSearches() { return this.$store.getters[this.maxNumberOfSearchesString]; },

    allSearchParameters() { return this.$store.getters[this.allSearchParametersString]; },

    // TODO: See if we can do this more elegant without hardcoded query name
    agendaQuery() { return this.$store.getters.agendaQuery; },

    isRecent() {
      return this.type === 'recent';
    },
    isSaved() {
      return this.type === 'saved';
    },
    isActive() {
      return (
        this.search.id
        && this.currentSearch
        && this.currentSearch.id === this.search.id
      );
    },
  },

  methods: {
    showShareSavedSearchDialog() {
      const data = {
        search: this.search,
        category: this.savedSearchesCategoryString,
      };
      showDialog(SavedSearchShareDialog, data);
    },

    // Hides the right sidebar
    hideSidebarRight() {
      this.$store.dispatch('setSidebarVisible', {
        sidebar: 'right',
        visible: false,
      });
    },

    // This is a replacement for something old
    triggerEditMode() {
      this.$gtag.event('saved-search-toggle-edit-mode', {
        event_category: 'saved-search',
      });
    },

    checkQueryParams() {
      let bool = false;

      // Do nothing if there is no context
      if (this.savedSearchesCategoryString === 'document' && this.allSearchParameters.context.length === 0) {
        this.$store.dispatch('setMessage', {
          message: 'Selecteer een context',
          type: 'info',
        });
        bool = true;
      }

      // Do nothing if there is no query string
      if (
        (['document', 'europarl', 'localRegional'].includes(this.savedSearchesCategoryString) && !this.allSearchParameters.query)
        || (this.savedSearchesCategoryString === 'agenda' && !this.agendaQuery)
      ) {
        this.$store.dispatch('setMessage', {
          message: 'Geef een onderwerp op',
          type: 'info',
        });
        bool = true;
      }

      return bool;
    },

    // Shows save form
    save() {
      this.$gtag.event('saved-search-save', {
        event_category: 'saved-search',
      });

      if (this.savedSearches.data.length >= this.maxNumberOfSearches) {
        this.$store.dispatch('setMessage', {
          message: 'Maximale aantal zoekopdrachten bereikt',
          type: 'error',
        });
        return null;
      }

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      this.$store.dispatch(this.setDialogFormSavedSearchesVisibleString, true);
      this.$store.dispatch(this.setCurrentSearchString, this.search);

      return null;
    },

    // Shows edit form
    edit() {
      this.$gtag.event('saved-search-edit', {
        event_category: 'saved-search',
      });

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      this.$store.dispatch(this.setDialogFormSavedSearchesVisibleString, true);
      this.$store.dispatch(this.setCurrentSearchString, this.search);

      return null;
    },

    // Removes the current search
    // After being confirmed in a modal dialog
    removeInit() {
      this.$gtag.event('saved-search-remove-confirm', {
        event_category: 'saved-search',
      });

      EventBus.$on('submitConfirmDialog', this.removeHard);
      this.$store.dispatch('setConfirmDialogHeader', 'Zoekopdracht verwijderen');
      this.$store.dispatch('setConfirmDialogBody', 'Weet je zeker dat je deze zoekopdracht wilt verwijderen?');
      this.$store.dispatch('setConfirmDialogVisible', true);
    },

    removeHard() {
      this.$gtag.event('saved-search-remove', {
        event_category: 'saved-search',
      });

      this.busyRemove = true;
      this.disabled = true;
      this.$store.dispatch(this.removeSearchString, this.search).then(
        () => {
          this.busyRemove = false;
          this.disabled = false;
        },
        () => { // on error
          this.busyRemove = false;
          this.disabled = false;
        },
      );
      // stop listening to events
      EventBus.$off('submitConfirmDialog');
    },

    // Updates the current search with the current search settings
    updateInit() {
      this.$gtag.event('saved-search-update-confirm', {
        event_category: 'saved-search',
      });

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      EventBus.$on('submitConfirmDialog', this.updateHard);
      this.$store.dispatch('setConfirmDialogHeader', 'Zoekopdracht overschrijven');
      this.$store.dispatch('setConfirmDialogBody', 'Weet je zeker dat je deze zoekopdracht wilt overschrijven?');
      this.$store.dispatch('setConfirmDialogVisible', true);

      return null;
    },

    updateHard() {
      this.$gtag.event('saved-search-update', {
        event_category: 'saved-search',
      });

      this.busyRemove = true;
      this.disabled = true;
      this.$store.dispatch(this.setCurrentSearchString, this.search);
      this.$store.dispatch(this.updateSearchString, this.search).then(
        () => {
          this.busyUpdate = false;
          this.disabled = false;
        },
        () => { // on error
          this.busyUpdate = false;
          this.disabled = false;
        },
      );
      EventBus.$off('submitConfirmDialog');
    },

    // Sets the search parameters to the ones of the current item
    async updateSearchParameters() {
      this.$gtag.event('saved-search-update-search-parameters', {
        event_category: 'saved-search',
      });

      if (this.isRecent && this.checkQueryParams()) {
        return null;
      }

      await this.$store.dispatch(this.setAllSearchParametersString,
        { ...this.search.queryDetails });
      await this.$store.dispatch(this.setCurrentSearchString, this.search);

      if (this.savedSearchesCategoryString === 'agenda') {
        this.$store.dispatch('getAllAgendas');
        // This seems to be necessary otherwise the url doesn't update accordingly
        this.$router
          .push({
            name: 'agenda',
            query: {
              query: this.search.queryDetails.query,
            },
          })
          .catch(() => {});
      }

      if (this.savedSearchesCategoryString === 'europarl') {
        // This seems to be necessary otherwise the url doesn't update accordingly

        const filters = {
          groupPaths: this.search.queryDetails.groupPaths.join(','),
          committees: this.search.queryDetails.euEpCommittees.join(',') || [],
        };

        this.$router
          .push({
            name: 'eu-dashboard',
            query: {
              query: this.search.queryDetails.query,
              ...filters,
            },
          })
          .catch(() => {});
      }

      if (this.savedSearchesCategoryString === 'document') {
        this.$router
          .push({
            name: 'dashboard',
            query: {
              query: this.search.queryDetails.query,
              context: this.search.queryDetails.context.join(','),
              groupPaths: this.search.queryDetails.groupPaths.join(','),
            },
          })
          .catch(() => {});
      }
      if (this.savedSearchesCategoryString === 'localRegional') {
        this.$router
          .push({
            name: 'local-regional-dashboard',
            query: {
              query: this.search.queryDetails.query,
              groupPaths: this.search.queryDetails.groupPaths.join(','),
            },
          })
          .catch(() => {});
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

  .query {
    border-left: 5px solid transparent !important;
  }

  .query--active {
    border-left: 5px solid lighten(teal, 10%) !important;
  }

  .fab-button {
    z-index: 10000 !important;
  }
</style>
