<template>
  <ul
    class="treeview pa-0"
    v-if="extendedItems && extendedItems.length > 0"
  >
    <li
      v-for="item in (virtualRootNode ? extendedItems[0].children : extendedItems)"
      :key="item[itemKey]"
    >
      <treeview-item
        :item="item"
        :openItems="openItems"
        :treePath="[item[itemKey]]"
        @checkbox-changed="checkboxChanged"
        :itemKey="itemKey"
        :itemText="itemText"
        :openAll="openAll"
        :selectedColor="selectedColor"
        :compact="compact"
      />
    </li>
  </ul>
</template>

<script>
import TreeviewItem from '@/components/TreeviewItem.vue';

// This component is designed as a drop-in replacement for vuetify's v-treeview.
// The difference being that the returned ids/codes work slightly differently:
// If a parent node is selected it will return its own id/code and not those of any of its children.

export default {
  name: 'Treeview',
  components: {
    TreeviewItem,
  },
  emits: ['input'],

  props: {
    items: Array,
    itemKey: String,
    itemText: String,
    virtualRootNode: Boolean,
    openItems: Array,
    value: Array, // Set via v-model
    openAll: Boolean,
    onlyLeafIds: {
      type: Boolean,
      default: false,
    },

    selectedColor: String,
    compact: Boolean,
  },

  data() {
    return {
      extendedItems: Array,
      changedInternally: false,
    };
  },

  created() {
    this.initialize();
  },

  watch: {
    value() {
      // `changedInternally` is a bit of a hack to detect if the update is comming from
      // the parent via v-model or if we have set it internally.
      if (!this.changedInternally) {
        // If value is changed from the outsite redraw the whole tree to reflect the changes.
        this.initialize();
      } else {
        this.changedInternally = false;
      }
    },
  },

  methods: {
    initialize() {
      this.extendedItems = this.items.map((item) => this.initializeItem(item));
    },
    // Called each time a checkbox in the item-tree has been clicked.
    checkboxChanged(eventData) {
      const { changedItem } = eventData;
      let { parents } = eventData;

      // Update children of the changed item
      this.updateItemChildren(changedItem, changedItem.checkboxValue);

      // Update parents of the changed item
      // Parent checkboxValue depends on all its children
      // so we need to make sure to update the lowest lying parents first
      if (this.virtualRootNode) {
        parents = [...parents, this.extendedItems[0]];
      }
      parents.forEach((item) => {
        item.checkboxValue = this.getParentItemCheckBoxValue(item);
      });

      this.changedInternally = true;
      // Emitting this event causes the v-model `value` prop to update both here and in the parent.
      this.$emit('input', this.checkedIdsList(this.extendedItems));
    },

    // Get a list of Ids/Codes of the checked items
    // If a parent item is checked we send its code and not any of its children codes.
    checkedIdsList(items) {
      return items.reduce(
        (checkedIdsList, item) => {
          if (item.checkboxValue === false) return checkedIdsList; // No selectedIds to be found here
          if (!(this.onlyLeafIds && item.children) && item.checkboxValue === true) {
            return checkedIdsList.concat([item[this.itemKey]]); // return own code
          }
          return checkedIdsList.concat(this.checkedIdsList(item.children));
        },
        [],
      );
    },

    // Apply new checkboxValue to all children
    updateItemChildren(item, checkboxValue) {
      if (item.children) {
        item.children.forEach((child) => {
          child.checkboxValue = checkboxValue;
          this.updateItemChildren(child, checkboxValue);
        });
      }
    },

    getParentItemCheckBoxValue(item) {
      if (item.children.every((child) => child.checkboxValue === true)) {
        return true;
      }
      if (item.children.some((child) => [true, 'indeterminate'].includes(child.checkboxValue))) {
        return 'indeterminate';
      }
      return false;
    },

    // Add the `checkboxValue`-field to the item and its children.
    initializeItem(item, parentSelected) {
      // Set intial value
      let checkboxValue;

      if (parentSelected) {
        // A parent id checked, so check self and children.
        checkboxValue = true;
        if (item.children) {
          item.children = item.children.map((child) => this.initializeItem(child, true));
        }
      } else if (item.children) { // Parent item
        // Check if checked
        const checked = this.value ? this.value.includes(item[this.itemKey]) : false;
        if (checked) {
          checkboxValue = true;
          // Also propegate value children
          item.children = item.children.map((child) => this.initializeItem(child, true));
        } else {
          // First decide value of children:
          item.children = item.children.map((child) => this.initializeItem(child));
          // ... then infer own value from children:
          checkboxValue = this.getParentItemCheckBoxValue(item);
        }
      } else { // Leaf item
        checkboxValue = this.value ? this.value.includes(item[this.itemKey]) : false;
      }

      // We need to use this.$set to add a property to the item object
      // because else vue will not track any changes to the property.
      // See: https://vuejs.org/v2/guide/reactivity.html
      this.$set(item, 'checkboxValue', checkboxValue);
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
  ul, ::v-deep ul {
    list-style-type: none;
  }
</style>
